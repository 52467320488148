@use "../../settings/variables";

.o-contact{
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  padding: 150px 0 150px 0;
  @media (max-width: variables.$pro){
    flex-direction: column;
  }
}
o-contact__left{
  min-width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  img{
    margin-right: 1em;
    border: 1px solid #000;
  }
  @media (max-width: variables.$pro) {
    margin-bottom: 200px;
  }
}
.o-contact__right {
  min-width: 50%;
  flex-grow: 1;
  overflow-x: hidden;
  @media (max-width: variables.$pro) {
    width: 100%;
  }
}

.test {
    border:tomato solid 2px;
    color: tomato;
}
.center{  
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.lighter{
  color:white !important;
}
.highlight{
    position:relative;
     margin:0.1em;
  }
.highlight::after {
    content:"";
    z-index:-1;
    left:0.5em;
    top:0.85em;
    border-width: 0.25em;
    border-style: solid;
    border-color:  $primary-color;
    background-color:   $primary-color;
    position:absolute;
    width:95%;
  }
  .primary{
    color: $primary-color;
  }
  .secundary{
    color: $secondary-color;
  }
  .third {
    color: $third-color;
  }

  .show-desktop {
    @media (max-width: $desktop) {
        display:none !important;
    };
}
.show-device {
    @media (min-width:  $desktop) {
        display:none;
    };
}
.hidden{
  display:none;
}
.diagonal {
  position: relative;
  z-index: 0;
  @include diagonal;
}

@media (max-width: $tablet) {
  .saveHeight {
    position: relative;
    height: 4em;
  }
  .cta-device {
    position: fixed;
    width: 100%;
    top: 10em;
    height: 0em;
    right: 0em;
    z-index: 1;
    transform: rotate(270deg) translateY(45.3vw);
    @media (max-width: 630px) {
      transform: rotate(270deg) translateY(43vw);

    }
    @media (max-width: 500px) {
      transform: rotate(270deg) translateY(41vw);

    }

  }
  @mixin cta{
    position: fixed;
    background: $darkColor;
    border-radius: 15px;
    padding:0.5em;
    right:0.5em;
    opacity: 0.6;
  }
  #cta{
    top:4em;
    @include cta;
  }
  #whatsapp {
    top:1em;
    @include cta;
  }
}
@media (min-width: $tablet) {
  @mixin cta{
    position: fixed;
    background: $darkColor;
    border-radius: 15px;
    padding:0.5em;
    right: 10vw;
    opacity: 0.6;
    z-index: 3;
  }
  #cta{
    top:6.4em;
    @include cta;
  }
  #whatsapp {
    top:3.2em;
    @include cta;

}
}

@use "../settings/variables";

a {
  color: variables.$primary-color;
  text-decoration: underline;

  &:hover,
  &:focus {
    color: darken(variables.$primary-color, 30%);
  }
}
button {
  font-family: variables.$normal;
}
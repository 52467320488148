@use "../../settings/variables";

.alert{
  position: relative;
  box-sizing: border-box;
  border-radius: variables.$border-radius;
  padding: 1.5em;
  color: variables.$text-color;
  border: 1px solid  variables.$primary;
  ul {
    margin:0;
    list-style: none;
  }
  &::before{
    position: absolute;
    content:"";
    top: -12px;
    left: -15px;
    background: url(../assets/border-top-left.svg);
    width: 60px;
    height: 60px;
    animation: Flash 3 0.5s ease-in-out;
  }
}
.alert--success{
  color: variables.$text-color;

}
.alert--danger{
  color: variables.$danger;

}
.alert--warning{
  color: variables.$warning;
}

@keyframes Flash {
  from { opacity: 0 }
  to { opacity: 1}
}
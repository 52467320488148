@use "../../settings/variables";

.o-contact__map {
  padding: 0 0 0 1rem;
  width: 100%;
  @media (max-width: variables.$pro){
    padding: 0;
    margin-top: 2em;
  }
}
.map {
  width: 100%;
  border: none;
}
@use "../../settings/variables";

.o-calidad {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  padding: 0;
  @media (max-width: variables.$pro){
    flex-direction: column-reverse;
  }
}

.o-calidad__left {
  min-width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media (max-width: variables.$pro) {
    margin-bottom: 200px;
  }
}
.o-calidad__imagen{
  width: 65%;
  max-width: 500px;
  min-width: 250px;
  height: auto;
  @media (max-width: variables.$device) {
    max-width: 250px;
  }

}
.o-calidad__right {
  min-width: 50%;
  padding: 200px 0 200px 0;
}
@use "../settings/variables";

h1, .h1
{
  font-weight: 700;
  line-height: 1.13;
  font-family: variables.$bold;
}
h2, h3, h4, .h2, h3, .h4 {
  font-family: variables.$bold;
  font-weight: normal;
  color: variables.$primary-color;
}

h1, .h1 {
  font-size: variables.$fs-title-l;
}

h2, .h2 {
  line-height: 2em;
  font-size: variables.$fs-title-m;
}

h3, .h3 {
  font-family: variables.$serif;
  font-size: variables.$fs-title-s;
  color: variables.$secondary-color;
}
@media (max-width:  variables.$tablet) {
 h2, .h2 {font-size: variables.$fs-title-m;}
  h3, .h3 { font-size: variables.$fs-title-s;}
}


@use "../../settings/variables";

.msg{
  position: relative;
  padding: variables.$spacing;
  border-radius: variables.$border-radius;
  border: 1px solid variables.$primary;
  //border-bottom: 5px solid variables.$primary;

  &::before{
    position: absolute;
    content:"";
    top: -12px;
    left: -15px;
    background: url(../assets/border-top-left.svg);
    width: 60px;
    height: 60px;
    animation: Flash 5 0.5s ease-in-out;
  }
}

@keyframes Flash {
  from { opacity: 0 }
  to { opacity: 1}
}
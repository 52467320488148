@use "../../settings/variables";

.o-caracteristicas {
  padding: 200px 0;
  overflow-x: hidden;
}
.caracterisctia__box {
  padding: variables.$spacing-l;
  border-radius: variables.$border-radius;
  box-shadow: #e2e2e2 0px 10px 40px;
  position: relative;
  background: url(../assets/border-top-left.svg) top left no-repeat;
  background-position: calc( (#{variables.$spacing-l} / 2) - 15px )  calc( (#{variables.$spacing-l} / 2) - 15px );
  background-size: 50px;
  h3{
    color: variables.$text-color;
    font-size: variables.$fs-lead;
    line-height: 1em;
    margin-top:0;
    padding-bottom: variables.$spacing;
    border-bottom: 2px solid variables.$GoldCrayola;
  }
}
@mixin mask {
  position:absolute;
  top: 0em;
  left: -3em;
  transform: scale(0.7);
}
.mask1{
  @include mask;
}
.mask2{
  @include mask;
}
.mask3{
  @include mask;
}
.mask4{
  @include mask;
}
@use "../../settings/variables";

$padding-left: 1.5rem;

.enums__number{
  font-family: variables.$serif-black;
  font-size: 3rem ;
  color: variables.$gray;
  line-height: 1em;
}
.enums__h2 {
  padding-left: variables.$spacing;
  font-family: variables.$serif-black;
  font-size: 2rem;
  line-height: 1em;
  color: variables.$darkColor;
  margin:0;
}
.enums__h3{
  padding-left: variables.$spacing;
  margin: variables.$spacing 0;
  font-family: variables.$normal ;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1em;
  color: variables.$text-over-light;
}
.enums__p{
  padding-left: variables.$spacing;
  margin-top: variables.$spacing;
  font-family: variables.$normal;
  font-size:  variables.$fs-body;
  line-height: variables.$lh-body;
  color: variables.$darkColor;
}
@use "../settings/variables";


.space {
  margin: 0 0 0 0.5em;
}
.p0{
  padding: 0;
}
.m0{
  margin: 0;
}
.p {
  padding: variables.$spacing;
}
.p-horizontal{
  padding: variables.$spacing 0;
}
.p-vertical{
  padding:  0 variables.$spacing;
}
.p-top{
  padding: variables.$spacing-l 0 0 0;
}
.p-bottom{
  padding: 0 0 variables.$spacing 0;
}
.p-left{
  padding: 0 0 0 variables.$spacing;
}
.p-right{
  padding: 0 variables.$spacing 0 0;
}

.m{
  margin:variables.$spacing;
}
.m-horizontal{
  margin: variables.$spacing 0;
}
.m-vertical{
  margin:  0 variables.$spacing;
}
.m-top{
  margin: variables.$spacing 0 0 0;
}
.m-bottom{
  margin: 0 0 variables.$spacing 0;
}
.m-left{
  margin: 0 0 0 variables.$spacing;
}
.m-right{
  margin: 0 variables.$spacing 0 0;
}
@use "../../settings/variables";

.nav{
  position: absolute;
  top: 1.5em;
  right: 1em;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.language-selector__list a{
  margin-right: 1em;
  text-decoration: none;
  color: variables.$background-light-color !important;
  background-color: variables.$darkColor;
  padding:0.4em;
  border-radius: variables.$border-radius;
  opacity: 0.3;
  &:hover {
    text-decoration: underline !important;
  }
}
.language-selector__current a{
  color: variables.$background-lighter-color  !important;
  opacity: 1;
}
@use "../../settings/variables";

.contact__form{
  box-sizing: border-box;
  width: 100%;
  padding: 1em;
}
.contact__form__inputs{
  box-sizing: border-box;
  textarea, input {
    box-sizing: border-box;
    padding: 1em;
    width: 100%;
    margin:1em 0;
    border-radius: 8px;
    border: lighten(variables.$gray-light, 20) 1px solid;
  }
  div{
    text-align: center;
    color: variables.$text-color;
  }
}
.contact__form__footer{
  display: flex;
  margin-top: variables.$spacing;
  align-items: center;
  justify-content: center;
}
.contact__form-sms{
  display: flex;flex-direction: column; align-items: center; justify-content: center;
}
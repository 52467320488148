@use "../../settings/variables";
$padding-border: 20px;
//$background-image: "../assets/right-background.jpg";
//$background-image--tablet: "../assets/right-background--tablet.jpg";
//$background-image--device: "../assets/right-background--device.jpg";

$_border-radius:  variables.$border-radius  35px 35px  variables.$border-radius  ;

.o-hero{
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: $_border-radius;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    background: variables.$darkColor;
    @media (max-width: 1000px) {
        flex-direction: column;
        border-radius:  variables.$border-radius;
        //background-image: url($background-image);
        background-image: none;
        background-position: top center;
        background-size: cover;
        background-repeat: no-repeat;
        @media (max-width: variables.$tablet) {
            background-image: none;
            //background-image: url($background-image--tablet);
        }
        @media (max-width: variables.$device) {
            //background-image: url($background-image--device);
            max-height:87vh;
        }
        @media (min-width: variables.$device) {
            &::after {
                content: "";
                width: 60px;
                height: 60px;
                position: absolute;
                background: url(../assets/border-bottom-right.svg);
                background-position: bottom right;
                bottom: $padding-border;
                right: $padding-border;
                z-index: 1;
            }
        }
    }
    &::after {
        content: "";
        width: 60px;
        height: 60px;
        position: absolute;
        background: url(../assets/border-bottom-right.svg);
        background-position: bottom right;
        bottom: $padding-border;
        right: $padding-border;
        z-index: 1;
    }
}
.o-hero__left{
    pointer-events: none;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;
    &::before {
        content: "";
        width: 60px;
        height: 60px;
        position: absolute;
        background: url(../assets/border-top-left.svg);
        background-position: top left;
        top: $padding-border;
        left: $padding-border;
        z-index: 1;
    }
}
.o-hero__right{
    //position: relative;
    //min-width: 50%;
    //height: 100%;
    //background-image:  url($background-image);
    //background-position: top center;
    //background-size: cover;
    //border-radius:  0 variables.$border-radius variables.$border-radius 0;
    //@media (max-width: 1000px) {
    //    display: none;
    //}

}

.hero__video{
    border-radius: $_border-radius;
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    pointer-events: none;
}
.hero__overlay{
    border-radius: $_border-radius;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: variables.$darkColor;
    pointer-events: none;
}

.hero__footer{
    position: absolute;
    max-width: 20em;
    width: 70%;
    bottom: 3em;
    left: 2em;
    color: variables.$lightColor;
    @media (max-width: variables.$device) {
        min-width: 150px;
        width: 250px;
        max-width: calc( #{variables.$device} - #{variables.$spacing-l}  );
    }
}
.hero__footer__title{
    font-size: variables.$fs-title-m;
    margin-bottom: 1em;
    color: variables.$lightColor;
    font-family: variables.$serif;
    @media (max-width: variables.$device) {
        font-size: variables.$fs-title-s;
    }
}
.hero__footer__text {
    font-size: variables.$fs-body;
    color: variables.$GoldCrayola;
}

@mixin divisor {
    position: absolute;
    width: 100vw;
    max-width: 100%;
    left: 0;
    border-bottom: 1px solid variables.$lightColor ;
    z-index: 1;
    opacity: 0.4;
    @media (max-width: variables.$tablet) {
        display: none;
    }
}
.hero__divisor--top {
    @include divisor;
    top: 5em;
}
.hero__divisor--bottom {
    @include divisor;
    bottom: 8em;
}
$font-family: "montserrat";

$normal: "barlowregular";
$medium: "barlowmedium";
$bold: "barlowbold";
$light: "barlowregular";
$serif: "playfair-display";
$serif-black: "playfair-display-black";

// Typography scale
$fs-base: 12;

$_fs-9: 9 / $fs-base + rem;
$_fs-12: 12 / $fs-base + rem;
$_fs-14: 14 / $fs-base + rem;
$_fs-19: 19 / $fs-base + rem;
$_fs-25: 25 / $fs-base + rem;
$_fs-33: 33 / $fs-base + rem;
$_fs-44: 44 / $fs-base + rem;

// Lineheight apllications
$lh-pill: (9 / $fs-base) * 1.2 + rem ;
$lh-btn: (12 / $fs-base) * 1.2 + rem ;
$lh-footer: (12 / $fs-base) * 1.2 + rem ;
$lh-menu: (14 / $fs-base) * 1.2 + rem ;
$lh-body: (14 / $fs-base) * 1.2 + rem ;
$lh-lead: (19 / $fs-base) * 1.2 + rem ;
$lh-title-s: (25 / $fs-base) * 1.2 + rem ;
$lh-title-m: (33 / $fs-base) * 1.2 + rem ;
$lh-title-l: (44 / $fs-base) * 1.2 + rem ;

// Typography applications
$fs-pill: $_fs-9;
$fs-btn: $_fs-12;
$fs-footer: $_fs-12;
$fs-menu: $_fs-14;
$fs-body: $_fs-14;
$fs-lead: $_fs-19;
$fs-title-s: $_fs-25;
$fs-title-m: $_fs-33;
$fs-title-l: $_fs-44;
@use "../settings/variables";

p{
    font-family: variables.$normal;
    line-height: 1.5em;
    padding: 0em;
    margin:0;
}
small{
    font-size: variables.$fs-pill;
    line-height: 1em;
}
b, strong{
    font-family: variables.$bold;
    font-weight: normal;
}
@media (max-width:  variables.$tablet) {
    p{ font-size: variables.$fs-body;}
}
$font-path: "../assets/fonts/";

@font-face {
    font-family: 'playfair-display';
    src:
      url( $font-path + 'playfair-display-v22-latin-500.woff2') format('woff2'),
      url( $font-path + 'playfair-display-v22-latin-500.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'playfair-display-black';
    src:
      url( $font-path + 'playfair-display-v22-latin-900.woff2') format('woff2'),
      url( $font-path + 'playfair-display-v22-latin-900.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: 'barlowbold';
  src:
    url( $font-path + 'barlow-bold-webfont.woff2') format('woff2'),
    url( $font-path + 'barlow-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'barlowmedium';
  src:
    url( $font-path + 'barlow-medium-webfont.woff2') format('woff2'),
    url( $font-path + 'barlow-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'barlowregular';
  src:
    url( $font-path + 'barlow-regular-webfont.woff2') format('woff2'),
    url( $font-path + 'barlow-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'barlowbold_italic';
  src: local('arial'),
  url( $font-path + 'barlow-bolditalic-webfont.woff2') format('woff2'),
  url( $font-path + 'barlow-bolditalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
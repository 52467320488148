
$White: #FFFFFF;
$Black: #000000;
$Platinum: #ECECEC;
$GoldCrayola: #DFDD55;
$IndianYellow: #868686;

$primary-color: $IndianYellow;
$secondary-color: #2B2B2B;
$third-color: #B7DDE1;



$gray: darken($Platinum, 40);
$gray-light: darken($Platinum, 20);

$primary: $GoldCrayola;
$darkColor: $Black;
$lightColor: #ECECEC;
$background: #F0F0F0;



$background-dark-color: #2B2B2B;
$background-darker-color: $Black;
$background-light-color: #e8eaed;
$background-lighter-color: #FFFFFF;

$text-color: $Black;
$text-over-light: $IndianYellow;

$success: $GoldCrayola;
$warning: yellow;
$danger: red;
$info: blue;
.contact__info{
  padding:1em 0;
  table{
    border-spacing: 1em 1.5em;
    tr{
      padding:0 1em;
    }
    td{
     min-width: 1.5em;
      line-height: 1em;
    }
  }

}
@use "../../settings/variables";


.background-lighter{
  background: variables.$background-lighter-color;
}
.background-light{
  background: variables.$background-light-color;
}

$diametro: 320px;

.diana__content{
  position: relative;
  z-index: 1;
}

.diana{
    position: absolute;
    content: "";
    background: variables.$gray-light;
    height: calc(#{$diametro} - 100px);
    width: calc(#{$diametro} - 100px);
    border-radius: 100% ;
    opacity: 1;
    z-index: -1;
    @media (max-width: variables.$device) {
      height: calc(250px - 100px);
      width: calc(250px  - 100px);
    }
}
.honda3{
  position: absolute;
  content: "";
  border: 1px solid  variables.$gray-light;
  height: $diametro;
  width: $diametro;
  border-radius: 100% ;
  opacity: 0.3;
  z-index: -1;
}
.honda2{
  position: absolute;
  content: "";
  border: 1px solid  variables.$gray-light;
  height: calc(#{$diametro} - 30px);
  width: calc(#{$diametro} - 30px);
  border-radius: 100% ;
  opacity: 0.6;
  z-index: -1;
}
.honda1{
  position: absolute;
  content: "";
  border: 1px solid  variables.$gray-light;
  height: calc(#{$diametro} - 60px);
  width: calc(#{$diametro} - 60px);
  border-radius: 100% ;
  opacity: 0.8;
  z-index: -1;
}
@media  (max-width: variables.$device) {
  $diametro: 250px;
  .honda3{
    height: $diametro;
    width: $diametro;
  }
  .honda2{
    height: calc(#{$diametro} - 30px);
    width: calc(#{$diametro} - 30px);
  }
  .honda1{
    height: calc(#{$diametro} - 60px);
    width: calc(#{$diametro} - 60px);
  }
}
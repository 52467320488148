@use "../../settings/variables";

.contact__form__checkbox{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
  input{
    width: 1.5em;
  }
  &--legal{
    font-size: variables.$fs-pill;
    line-height: 1em;
    text-align: left;
    max-width: 90%;
    span {
      padding-left: 0.5em;
    }
  }
}
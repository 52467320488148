@use "../../settings/variables";

@mixin submit {
  padding: 0.6em 2.5em 0.4em 2.5em;
  color: variables.$background-lighter-color !important;
  background-color: variables.$GoldCrayola;
  border-radius: variables.$border-radius;
  text-align: center;
  text-transform: uppercase;
  line-height: 1em;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    color: variables.$background-lighter-color !important;
    background-color: variables.$gray-light;
  }
  &:focus{
    border: 2px solid #000;
  }
}
@mixin white {
  background-color: variables.$background-lighter-color;
  color: variables.$text-color !important;
  font-family: variables.$bold;
  &:hover {
    color: variables.$background-lighter-color !important;
    background-color: variables.$background-light-color;
  }
}

.submit{
  @include submit;
  appearance: none;
  outline: none;
  border:none;
  &--block{
    display: block;
    width: fit-content;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  &__link {
    @include submit;
    &--white {
      @include white;
    }
  }
  &__icon {
    padding: 0.5em;
    display: flex;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    &--white {
      @include white;
    }
  }
}


@use "../settings/variables";

body {
  color: variables.$text-color;
  font-family: variables.$normal;
  font-size: variables.$fs-body;
  background-color: variables.$background-light-color;
  line-height: 1em;
  overflow-x: hidden;
}
html {
  scroll-behavior: smooth;
}
@import "./colors";
@import "./layout";
@import "./typography";
@import "./fonts";
@import "./z-index";

@mixin diagonal {
  &:before {
    content: "";
    position: absolute;
    height: 4em;
    top: -2.5em;
    width: 100%;
    background: $background;
    transform: skew(0, 358deg);
    z-index: 0;
  }
  //&:after {
  //  content: "";
  //  position: absolute;
  //  height: 4em;
  //  bottom: -2.5em;
  //  width: 100%;
  //  background: $background;
  //  transform: skew(0, 358deg);
  //  z-index: 0;
  //}
}
$max-width: 71.25rem;
$border-radius: 18px;

// Spacing
$spacing-s: 1rem;
$spacing: 1.6rem;
$spacing-l: 3.25rem;

//query
$device: 470px;
$tablet: 768px;
$pro: 1024px;
$desktop: 1300px;

@use "../settings/variables";

@mixin device-column {
  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
  }
}

@mixin list-align {
  li {
    //display: inline-flex;
    padding: 0 1em;
    align-self: center;
    text-align: right;

    a, p, span, img {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
.docs {
  max-width: 960px;
  margin: 0 auto;
  padding: 1.5em 2em;
}

.container {
  max-width: variables.$max-width;
  margin: 0 auto;
  width: 90%;

  @media (min-width: variables.$desktop) {
    width: 90%;
  }

  @media (min-width: variables.$tablet) {
    width: 80%;
  }
}
.grid-2x2{
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  grid-gap: variables.$spacing-l;
  @include device-column;
}
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  @media (max-width: variables.$desktop) {
    display: flex;
    flex-direction: column;
  }
}
.space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @include device-column;
}
.list-clean {
  list-style: none;
  padding: 0;
  @include list-align;
}
.list-horizontal {
  list-style: none;
  display: flex;
  @include list-align;
}
.media-in{
  @media (max-width: variables.$desktop) {
    display: flex;
    flex-direction: column;
  }
}
.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.fullcontent{
  box-sizing: border-box;
  position: relative;
  width: 100vw;
  max-width:100%;
}
.fullscreen{
  box-sizing: border-box;
  position: relative;
  width: 100vw;
  height: 100vh;
  //height: calc(var(--vh, 1vh) * 100);
  max-height: 100%;
  padding: variables.$spacing;
}